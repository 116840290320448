import { initialState } from "./AppContext"

const SAVE_AGREE_FLEXMED = "SAVE_AGREE_FLEXMED"
const SAVE_AGREE_EPHARMACY = "SAVE_AGREE_EPHARMACY"
const SAVE_MEDICINES = "SAVE_MEDICINES"
const SAVE_ORDERING_FOR = "SAVE_ORDERING_FOR"
const SAVE_BENEFIT_TYPE = "SAVE_BENEFIT_TYPE"
const SAVE_BENEFICIARY_DETAILS = "SAVE_BENEFICIARY_DETAILS"
const SAVE_DOCUMENTS = "SAVE_DOCUMENTS"
const SAVE_BENEFICIARY_DOCUMENTS = "SAVE_BENEFICIARY_DOCUMENTS"
const SAVE_RX_REQUIRED = "SAVE_RX_REQUIRED"

const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS"
const SAVE_DELIVERY_DETAILS = "SAVE_DELIVERY_DETAILS"
const SAVE_RECEIPT = "SAVE_RECEIPT"
const RESET_DETAILS = "RESET_DETAILS"
const SHOW_MODAL = "SHOW_MODAL"
const HIDE_MODAL = "HIDE_MODAL"
const SAVE_RECEIPT_ITEMS = "SAVE_RECEIPT_ITEMS"
const SAVE_PRESCRIPTION_FOR = "SAVE_PRESCRIPTION_FOR"

export let AppReducer = (state, action) => {
  switch (action.type) {
    case SAVE_AGREE_FLEXMED:
      return {
        ...state,
        flexmed: { ...state.flexmed, agreeToMechanics: action.payload },
      }
    case SAVE_AGREE_EPHARMACY:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, agreeToMechanics: action.payload },
      }
    case SAVE_MEDICINES:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: action.payload },
      }
    case SAVE_ORDERING_FOR:
      return {
        ...state,
        flexmed: { ...state.flexmed, orderingFor: action.payload },
      }
    case SAVE_PRESCRIPTION_FOR:
      return {
        ...state,
        flexmed: { ...state.flexmed, prescriptionFor: action.payload },
      }
    case SAVE_DOCUMENTS:
      return {
        ...state,
        flexmed: { ...state.flexmed, documents: [...action.payload] },
      }

    case SAVE_BENEFICIARY_DOCUMENTS:
      return {
        ...state,
        flexmed: {
          ...state.flexmed,
          beneficiaryDocuments: [...action.payload],
        },
      }
    case SAVE_PERSONAL_DETAILS:
      return {
        ...state,
        flexmed: { ...state.flexmed, ...action.payload },
      }

    case SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }
    case SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }
    case HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal } }
    case RESET_DETAILS:
      return { ...initialState }

    case SAVE_RECEIPT:
      return {
        ...state,
        flexmed: {
          ...state.flexmed,
          receipts: [...action.payload],
        },
      }

    case SAVE_BENEFIT_TYPE:
      return {
        ...state,
        flexmed: { ...state.flexmed, benefitType: action.payload },
      }
    case SAVE_BENEFICIARY_DETAILS:
      return {
        ...state,
        flexmed: { ...state.flexmed, beneficiaryDetails: action.payload },
      }
    case SAVE_RX_REQUIRED:
      return {
        ...state,
        flexmed: { ...state.flexmed, rxRequired: action.payload },
      }
    case SAVE_RECEIPT_ITEMS:
      return { ...state, flexmed: { ...state.flexmed, ...action.payload } }
  }
}
